import Hash from "@/shared/helpers/Hash";

export default class API {

	constructor(baseUrl, loginUrl) {

		this.domain = baseUrl;
		this.loginUrl = loginUrl;
		this.oauthToken = null;

		this.DEBUG = false;
		this.VERSION = "/v1";
		this.ENDPOINT = this.domain + "/API" + this.VERSION;
		this.ENDPOINT_USER = this.ENDPOINT + "/user";
		this.ENDPOINT_BUSINESS = this.ENDPOINT + "/businesses/";

		this.TOKEN_SALT = "mad1tApPs!-rE7be31JA42341qqr";
		this.CONTENT_TYPE = "application/x-www-form-urlencoded;charset=UTF-8";

		this.CLIENT_ID = 'webapp';
		this.CLIENT_SECRET = 'G8N13fnrhmHN621bdhbzoWc66zCqBQ4H2a89P9a6u45bHfUSx87esurUE5';

	}

	static init(baseUrl = null, loginUrl = null) {

		if (!!API.instance && baseUrl === null && loginUrl === null) {
			return API.instance;
		}
		API.instance = new API(baseUrl, loginUrl);
		return API.instance;

	}

	joinUrlParams(params) {

		if (params) {
			return Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
		} else {
			return "";
		}

	}

	setParams(url, data) {

		if (data && typeof data === 'object' && Object.keys(data).length > 0) {
			const params = this.joinUrlParams(data);
			url += '?' + params;
		}

		return url;

	}

	generateToken(paramsArray) {

		let joinParams = this.TOKEN_SALT + ":";
		paramsArray.forEach((param) => {
			joinParams += param;
			joinParams += "&";
		});
		joinParams = joinParams.slice(0, -1);
		return Hash.init().calculate(joinParams);

	}

	get(url, data, callback, withStatus = false) {

		let finalUrl = this.setParams(url, data);

		let self = this;

		fetch(finalUrl, {
			method: 'GET',
			cache: "no-store",
			headers: {
				"Authorization": "Bearer " + self.oauthToken,
				"Content-type": this.CONTENT_TYPE
			}
		})
			.then(response => {

				if (this.DEBUG) {
					return response.text();
				} else if (response.status === 401) {
					let refreshToken = localStorage.getItem("refresh_token");
					if (refreshToken) {
						API.init().loginUserRefreshToken(refreshToken, (response) => {

							if ("access_token" in response) {
								API.init().oauthToken = response.access_token;
								sessionStorage.setItem("oauth_token", response.access_token);
								if (!API.init().oauthToken || API.init().oauthToken === "") {
									window.location.replace(API.init().loginUrl);
								}
								API.init().get(url, data, callback, withStatus);
							} else {

								localStorage.removeItem("refresh_token");
								window.location.replace(API.init().loginUrl);
							}

						});

					} else {
						window.location.replace(this.loginUrl);
					}
					return null;
				} else if (response.status === 429) {
					alert("Please wait and retry later. Thanks!");
				} else {
					if (withStatus) {
						callback(response, response.status);
					} else {
						return response.json();
					}
				}

			})
			.then((response) => {
				if (this.DEBUG) {
					console.log('GET Fetching ' + finalUrl);
					console.log('Response: ' + response);
				} else if (response) {
					callback(response);
				}

			})
			.catch((error) => {
				console.error('Error: ', error);
			});

	}

	fetchWithTimeout(resource, options = {}) {
		const { timeout = 8000 } = options;

		const controller = new AbortController();
		const id = setTimeout(() => {
			console.error("Fetch timed out (short)", resource);
			controller.abort();
		}, timeout);
		const response = fetch(resource, {
			...options,
			signal: controller.signal
		});
		return response.finally(() => {
			clearTimeout(id)
		});
	}

	post(url, data, callback, callbackError = null, shortTimeout = false, forceText = false) {

		const formBody = this.joinUrlParams(data);

		let self = this;

		let fetchFunction = shortTimeout ? this.fetchWithTimeout : fetch;

		fetchFunction(url, {
			method: 'POST',
			body: formBody,
			cache: "no-store",
			headers: {
				"Authorization": "Bearer " + self.oauthToken,
				"Content-type": this.CONTENT_TYPE
			}
		})
			.then(response => {
				if (this.DEBUG) {
					return response.text();
				} else if (response.status === 401) {

					let refreshToken = localStorage.getItem("refresh_token");
					if (refreshToken) {

						API.init().loginUserRefreshToken(refreshToken, (response) => {

							if ("access_token" in response) {
								API.init().oauthToken = response.access_token;
								sessionStorage.setItem("oauth_token", response.access_token);
								if (!API.init().oauthToken || API.init().oauthToken === "") {
									window.location.replace(API.init().loginUrl);
								}
								API.init().post(url, data, callback, callbackError);
							} else {
								callbackError && callbackError(response);
								localStorage.removeItem("refresh_token");
								window.location.replace(API.init().loginUrl);
							}

						}, callbackError);

					} else {
						callbackError && callbackError(response);
						window.location.replace(this.loginUrl);
					}
					return null;
				} else if (response.status === 429) {
					callbackError && callbackError(response);
					alert("Please wait and retry later. Thanks!");
					return null;
				} else {
					if(forceText) {
						return response.text();
					} else {
						return response.json();
					}
				}
			})
			.then((response) => {
				if (this.DEBUG) {
					console.log('POST Fetching ' + url);
					console.log('Data: ' + formBody);
					console.log('Response: ' + response);
					callbackError && callbackError(response);
				} else if (response) {
					callback(response);
				} else {
					callbackError && callbackError(response);
				}
			})
			.catch((error) => {
				console.log('Error: ', error);
				callbackError && callbackError(error);
			});

	}

	getPublic(url, callback, callbackError = null, shortTimeout = false) {

		let fetchFunction = shortTimeout ? this.fetchWithTimeout : fetch;

		fetchFunction(url, {
			method: 'GET',
			cache: "no-store",
		})
			.then((response) => {

				if (this.DEBUG) {
					return response.text();
				} else if (response.status === 429) {
					callbackError && callbackError(response);
					alert("Please wait and retry later. Thanks!");
					return null;
				} else {
					return response.json();
				}

			})
			.then((response) => {

				if (this.DEBUG) {
					console.log('get Fetching ' + url);
					console.log('Response: ' + response);
					callbackError && callbackError(response);
				} else {
					callback(response);
				}

			})
			.catch((error) => {
				console.error('Error: ', error);
				callbackError && callbackError(error);
			});

	}

	postPublic(url, data, callback, callbackError = null, shortTimeout = false) {

		const formBody = this.joinUrlParams(data);
		let fetchFunction = shortTimeout ? this.fetchWithTimeout : fetch;

		fetchFunction(url, {
			method: 'POST',
			body: formBody,
			headers: {
				"Content-type": this.CONTENT_TYPE
			}
		})
			.then((response) => {

				if (this.DEBUG) {
					return response.text();
				} else {
					return response.json();
				}

			})
			.then((response) => {

				if (this.DEBUG) {
					console.log('post Fetching ' + url);
					console.log('Data: ' + formBody);
					console.log('Response: ' + response);
					callbackError && callbackError(response);
				} else {
					callback(response);
				}

			})
			.catch((error) => {
				callbackError && callbackError(error);
			});

	}

	signup(data, callback) {

		this.postPublic(this.ENDPOINT + "/signup", data, callback);

	}

	resetPassword(email, password, captcha, lang, callback) {

		let token = this.generateToken([email, password, captcha]);
		let data = {
			lang: lang,
			email: email,
			password: password,
			captcha: captcha,
			token: token,
			os: "webapp"
		};
		this.postPublic(this.ENDPOINT + "/reset-password", data, callback);

	}

	verifyCode(code, toUpdate, callback) {

		let token = this.generateToken([code]);
		let data = {
			token: token,
			code: code,
			to_update: toUpdate
		};
		this.postPublic(this.ENDPOINT + "/verify-code", data, callback);

	}

	verifyCodeSpeedy(code, phone, password, name, callback) {

		let token = this.generateToken([code, password]);
		let data = {
			token: token,
			code: code,
			phone: phone,
			password: password,
			name: name
		};
		this.postPublic(this.ENDPOINT + "/verify-code/speedy", data, callback);

	}

	newLead(captcha, name, email, phone, company, origin, callback) {
		let token = this.generateToken([captcha, name, email, phone]);
		let data = {
			captcha: captcha,
			name: name,
			email: email,
			phone: phone,
			company: company,
			origin: origin,
			token: token
		};
		this.postPublic(this.ENDPOINT + "/crm/webhook", data, callback);
	}

	requestInvoice(orderId, name, vat, cf, sdi, address, callback) {
		let token = this.generateToken([name, vat, sdi]);
		let data = {
			name: name,
			vat: vat,
			cf: cf,
			sdi: sdi,
			address: address,
			token: token
		};
		this.postPublic(this.ENDPOINT + "/orders/" + orderId + "/invoice", data, callback);
	}

	getBusinessSocials(businessId, callback) {

		this.getPublic(this.ENDPOINT_BUSINESS + businessId + "/public/socials", callback);

	}

	getMenuItemLists(businessId, itemId, composite, deepen, callback) {

		let data = {
			composite: composite,
			deepen: deepen
		}
		this.postPublic(this.ENDPOINT_BUSINESS + businessId + "/public/menu/items/" + itemId, data, callback);

	}

	getMenuItemListsInfo(businessId, itemId, composite, deepen, callback) {

		let data = {
			composite: composite,
			deepen: deepen
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + itemId + "/info", data, callback);

	}

	getNewMenuItemExtras(businessId, itemId, callback) {

		this.getPublic(this.ENDPOINT_BUSINESS + businessId + "/public/menu/items/" + itemId + "/extras", callback);

	}

	translateMenu(businessId, languageCodeFrom, languageCodeTo, products, categories, menus, description, cover_charge_name, items, lists, callback) {

		let itemsString = JSON.stringify(items);
		let token = this.generateToken([languageCodeFrom, languageCodeTo, itemsString]);
		let data = {
			language_code_from: languageCodeFrom,
			language_code_to: languageCodeTo,
			products: JSON.stringify(products),
			categories: JSON.stringify(categories),
			menus: JSON.stringify(menus),
			description: description,
			cover_charge_name: cover_charge_name,
			items: itemsString,
			lists: JSON.stringify(lists),
			token: token
		};
		this.postPublic(this.ENDPOINT_BUSINESS + businessId + "/public/menu/translate", data, callback);
	}

	checkAvailability(businessId, timeChecks, callback) {

		let data = {
			time_checks: JSON.stringify(timeChecks),
		};
		this.postPublic(this.ENDPOINT_BUSINESS + businessId + "/public/menu/availability", data, callback);

	}

	translateInterface(languageCodeTo, files, keys, callback) {

		let token = this.generateToken([languageCodeTo, files]);
		let data = {
			language_code_to: languageCodeTo,
			files: files,
			keys: keys,
			token: token
		};
		this.postPublic(this.ENDPOINT + "/translate/interface", data, callback);
	}

	getPublicTakeawayTimes(businessId, callback) {

		this.getPublic(this.ENDPOINT_BUSINESS + businessId + "/public/takeaway/times", callback);

	}

	getOrdersQueue(businessId, orderId, checkOrdersQueue, checkPayBill, checkCalls, tkn, callback, callbackError) {

		let token = this.generateToken([businessId, tkn ? tkn : '-']);
		const data = {
			order_id: orderId,
			check_orders_queue: checkOrdersQueue,
			check_pay_bill: checkPayBill,
			check_calls: checkCalls,
			tkn: tkn,
			token: token
		};
		let finalUrl = this.setParams(this.ENDPOINT_BUSINESS + businessId + "/public/queue", data);
		this.getPublic(finalUrl, callback, callbackError, true);

	}

	canOrderDelivery(businessId, deliveryTimeId, callback) {

		let data = { delivery_time_id: deliveryTimeId }
		this.postPublic(this.ENDPOINT_BUSINESS + businessId + "/public/delivery/available", data, callback);

	}

	checkoutAddEmail(businessId, email, callback) {

		let token = this.generateToken([businessId, email]);
		let data = {
			business_id: businessId,
			email: email,
			token: token,
		};
		this.postPublic(this.ENDPOINT + "/checkout/email", data, callback);

	}

	checkoutRemovePaymentMethod(businessId, paymentMethodId, callback) {

		let token = this.generateToken([businessId]);
		let data = {
			business_id: businessId,
			token: token,
			payment_method_id: paymentMethodId
		};
		this.postPublic(this.ENDPOINT + "/checkout/payment-method/delete", data, callback);

	}

	updatePageVt(businessId, menuId, roomTableId, roomId, callback = ()=>{}) {

		let token = this.generateToken([businessId]);
		let data = {
			business_id: businessId,
			menu_id: menuId,
			room_table_id: roomTableId,
			room_id: roomId,
			token: token
		}
		this.post(this.ENDPOINT + "/page/vt", data, callback);
	}

	askForAssistance(businessId, roomTableId, roomId, version, callback = ()=>{}) {

		let token = this.generateToken([businessId, roomTableId, roomId, version]);
		let tkn = typeof tknTmp !== 'undefined' ? tknTmp : null;
		let data = {
			business_id: businessId,
			room_table_id: roomTableId,
			room_id: roomId,
			token,
			tkn,
			version
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/public/assistance", data, callback);
	}

	cancelAssistance(businessId, roomTableId, roomId, version, callback = ()=>{}) {

		let token = this.generateToken([businessId, roomTableId, roomId, version]);
		let tkn = typeof tknTmp !== 'undefined' ? tknTmp : null;
		let data = {
			business_id: businessId,
			room_table_id: roomTableId,
			room_id: roomId,
			token,
			tkn,
			version
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/public/assistance/cancel", data, callback);
	}

	updatePageEvs(actions, businessId, menuId, roomTableId, sessionToken, visitToken, checkRoomTableCalls, callback = ()=>{}) {

		let token = this.generateToken([businessId, sessionToken, visitToken]);
		let data = {
			business_id: businessId,
			menu_id: menuId,
			room_table_id: roomTableId,
			check_room_table_calls: checkRoomTableCalls,
			session_token: sessionToken,
			visit_token: visitToken,
			actions: actions,
			token: token
		}
		this.post(this.ENDPOINT + "/page/evs", data, callback);
	}

	getRates(businessId, delivery_address_id, user_id, address_data, phone, name, email, products, callback) {

		let token = this.generateToken([businessId, products]);
		let data = {
			businessId: businessId,
			delivery_address_id: delivery_address_id,
			user_id: user_id,
			address_data: address_data,
			phone: phone,
			name: name,
			email: email,
			products: products,
			token: token
		};
		this.postPublic(this.ENDPOINT_BUSINESS + businessId + "/public/rates", data, callback);

	}

	getTakeawayTimesDay(businessId, date, callback) {

		let token = this.generateToken([date]);
		let data = {
			date: date,
			token: token
		}
		this.postPublic(this.ENDPOINT_BUSINESS + businessId + "/public/takeaway/day", data, callback);

	}

	getDeliveryTimesDay(businessId, date, callback) {

		let token = this.generateToken([date]);
		let data = {
			date: date,
			token: token
		}
		this.postPublic(this.ENDPOINT_BUSINESS + businessId + "/public/delivery/day", data, callback);

	}

	addOrderCounter(businessId, mode, items, subtotal_delta, notes, name, email, phone, room_table_id, delivery_address_id, cover_change_num, cover_change_price, cover_paid, takeaway_costs, delivery_costs, status, type, paid, invoice_paid, notInPlaceDate, amountPaidPartial, invoiceData, unlockUserId, sendBusinessAction, callback) {

		let data = {
			mode: mode,
			items: items,
			subtotal_delta: subtotal_delta,
			notes: notes,
			name: name,
			email: email,
			phone: phone,
			room_table_id: room_table_id,
			delivery_address_id: delivery_address_id,
			cover_change_num: cover_change_num,
			cover_change_price: cover_change_price,
			cover_paid: cover_paid,
			takeaway_costs: takeaway_costs,
			delivery_costs: delivery_costs,
			status: status,
			type: type,
			paid: paid,
			invoice_paid: invoice_paid,
			not_in_place_date: notInPlaceDate,
			amount_paid_partial: amountPaidPartial,
			unlock_user_id: unlockUserId,
			invoiceData: invoiceData,
			business_action: sendBusinessAction
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/counter", data, callback);

	}

	editOrder(businessId, orderId, orderMode, roomTableId, items, subtotal_delta, notes, cover_change_num, cover_change_price, takeaway_costs, delivery_costs, businessAction, callback) {

		let data = {
			order_id: orderId,
			order_mode: orderMode,
			room_table_id: roomTableId,
			items: items,
			subtotal_delta: subtotal_delta,
			notes: notes,
			cover_change_num: cover_change_num,
			cover_change_price: cover_change_price,
			takeaway_costs: takeaway_costs,
			delivery_costs: delivery_costs,
			business_action: businessAction
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/edit", data, callback);

	}

	searchMatchingCustomers(businessId, company, vat, callback) {
		let data = {
			company: company,
			vat: vat
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/counter/customers/search", data, callback);
	}

	getFilteredCustomersPeople(businessId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/list", data, callback);

	}

	getFilteredCustomersCompanies(businessId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/list", data, callback);

	}

	getFilteredPluginLogs(businessId, pluginId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/plugins/" + pluginId + "/logs", data, callback);

	}

	getFilteredPersonOrders(businessId, customerId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/"+customerId+"/orders/list", data, callback);

	}

	getPlugins(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/plugins", null, callback);

	}

	addPlugin(businessId, name, description, activeUrl, color, icon, secret, callback) {

		let data = {
			name: name,
			description: description,
			active_url: activeUrl,
			color: color,
			icon: icon,
			secret: secret
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/plugins", data, callback);

	}

	activatePlugin(businessId, pluginId, externalLocationId, secret, callback) {

		let data = {
			external_location_id: externalLocationId,
			secret: secret
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/plugins/" + pluginId + "/activate", data, callback);

	}

	deletePlugin(businessId, pluginId, callback) {

		let data = {
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/plugins/" + pluginId + "/delete", data, callback);

	}

	disablePlugin(businessId, pluginId, callback) {

		let data = {
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/plugins/" + pluginId + "/deactivate", data, callback);

	}

	syncMenu(businessId, pluginId, menuId, percentDifference, autoAssignSku, callback) {

		let data = {
			percent_difference: percentDifference,
			auto_assign_sku: autoAssignSku,
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/plugins/" + pluginId + "/menus/" + menuId, data, callback);

	}

	getCustomer(businessId, customerId, customerType, callback) {

		let data = {
			customer_type: customerType
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/"+customerId+"/single", data, callback);

	}

	getFilteredCompanyOrders(businessId, customerId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/"+customerId+"/orders/list", data, callback);

	}

	getFilteredCompanyPeople(businessId, customerId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/"+customerId+"/people/list", data, callback);

	}

	retryInvoice(businessId, invoiceId, callback) {

		let data = {
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/invoices/retry/" + invoiceId, data, callback);

	}

	switchInvoiceCustomer(businessId, invoiceId, customerId, callback) {

		let data = {}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/invoices/switch/" + invoiceId + "/" + customerId, data, callback);

	}

	printInvoice(businessId, invoiceId, callback) {

		let data = {
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/invoices/print/" + invoiceId, data, callback);

	}

	downloadInvoiceXml(businessId, invoiceId, callback) {

		let data = {
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/invoices/xml/" + invoiceId, data, callback, null, false, true);

	}

	addCustomerPerson(businessId, customerId, name, email, phone, fiscal, gender, birthDate, address, notes, callback) {

		let data = {
			name: name,
			email: email,
			phone: phone,
			fiscal: fiscal,
			gender: gender,
			birth_date: birthDate,
			address: address,
			notes: notes,
			customer_id: customerId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/add", data, callback);

	}

	deleteCustomerPerson(businessId, customerId, callback) {

		let data = {}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/"+customerId+"/delete", data, callback);

	}

	deleteCustomerCompany(businessId, customerId, callback) {

		let data = {}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/"+customerId+"/delete", data, callback);

	}

	payCustomersOrders(businessId, orders, paymentType, total, customer_id, customer_type, callback) {

		let data = {
			orders: JSON.stringify(orders),
			payment_type: paymentType,
			customer_id: customer_id,
			customer_type: customer_type,
			total: total
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/pay", data, callback);

	}

	payCompanyAssociatesOrders(businessId, orders, paymentType, total, customer_id, callback) {

		let data = {
			orders: JSON.stringify(orders),
			payment_type: paymentType,
			customer_id: customer_id,
			total: total
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/pay", data, callback);

	}

	getCustomersOrdersTotal(businessId, customerId, customers, callback) {

		let data = {
			customers: customers ? JSON.stringify(customers) : null,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/"+customerId+"/people/total", data, callback);

	}

	getCustomersAllOrders(businessId, customerId, type, callback) {

		let data = {
			type: type,
			customer_id: customerId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/orders/total", data, callback);

	}

	addCustomerCompany(businessId, customerId, name, vat, cf, sdi, address, deliveryAddress, pec, notes, callback) {

		let data = {
			name: name,
			sdi: sdi,
			vat: vat,
			cf: cf,
			address: address,
			delivery_address: deliveryAddress,
			pec: pec,
			notes: notes,
			customer_id: customerId,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/add", data, callback);

	}

	associateCustomerToCompany(businessId, companyCustomerId, personCustomerId, callback) {

		let data = {
			person_customer_id: personCustomerId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/"+companyCustomerId+"/associate", data, callback);

	}

	removeCustomerCompanyPerson(businessId, companyCustomerId, personCustomerId, callback) {

		let data = {
			person_customer_id: personCustomerId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/companies/"+companyCustomerId+"/associate/remove", data, callback);

	}

	getMostSoldProducts(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/orders/counter/most", null, callback);

	}

	addOrderMultiPayment(businessId, orderIds, type, amount, itemsSplit, callback) {

		let data = {
			type: type,
			amount: amount,
			order_ids: orderIds,
			items_split: itemsSplit,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/payment/multi", data, callback);

	}

	checkoutInPlace(mode, businessId, captcha, items, notes, name, version, table, seat, cover_change_num, cover_change_price, cover_info, type_qr, email, phone, type_notification, tkn, terminalId, coupons, buffering, callback) {

		let token = this.generateToken([captcha, businessId]);
		let data = {
			token: token,
			captcha: captcha,
			items: items,
			notes: notes,
			name: name,
			version: version,
			table: table,
			seat: seat,
			cover_change_num: cover_change_num,
			cover_change_price: cover_change_price,
			cover_info: cover_info,
			type_qr: type_qr,
			email: email,
			phone: phone,
			type_notification: type_notification,
			tkn: tkn,
			business_id: businessId,
			terminal_id: terminalId,
			mode: mode,
			coupons: JSON.stringify(coupons),
			buffering: buffering
		};
		this.postPublic(this.ENDPOINT + "/checkout/in-place", data, callback);

	}

	payBill(mode, businessId, amount, captcha, tkn, version, orderIds, typeIndex, itemsRedeemed, callback) {

		let token = this.generateToken([captcha, businessId, mode]);
		let data = {
			token: token,
			captcha: captcha,
			business_id: businessId,
			mode: mode,
			amount: amount,
			tkn: tkn,
			version: version,
			order_ids: JSON.stringify(orderIds),
			type: typeIndex,
			items_redeemed: itemsRedeemed ? JSON.stringify(itemsRedeemed) : null
		};
		this.postPublic(this.ENDPOINT + "/checkout/in-place/bill", data, callback);

	}

	checkoutNotInPlace(businessId, captcha, items, notes, typePayment, name, email, phone, address, addressDetails, lat, lng, dataAddress, scheduledDate, rateId, orderMode, coupons, callback) {

		let token = this.generateToken([captcha, businessId]);
		let data = {
			token: token,
			captcha: captcha,
			items: items,
			notes: notes,
			type_payment: typePayment,
			name: name,
			email: email,
			phone: phone,
			address: address,
			address_details: addressDetails,
			lat: lat,
			lng: lng,
			data: dataAddress,
			scheduled_date: scheduledDate,
			rate_id: rateId,
			order_mode: orderMode,
			business_id: businessId,
			coupons: JSON.stringify(coupons)
		};
		this.postPublic(this.ENDPOINT + "/checkout/not-in-place", data, callback);

	}


	checkoutPos(captcha, value, businessId, btc, orderImportId, callback) {

		let token = this.generateToken([captcha, value, businessId]);
		let data = {
			token: token,
			captcha: captcha,
			value: value,
			business_id: businessId,
			order_import_id: orderImportId,
			btc: btc,
			test: TEST
		};
		this.postPublic(this.ENDPOINT + "/checkout/pos", data, callback);

	}

	saveCsData(csData, callback) {

		let token = this.generateToken([csData]);
		let data = {
			token: token,
			csData: csData
		};
		this.postPublic(this.ENDPOINT + "/cs/data", data, callback);

	}

	getOrderMessages(orderId, callback) {

		this.getPublic(this.ENDPOINT + "/orders/" + orderId + "/messages", callback, null, true)

	}

	getOrderStatus(orderId, callback, callbackError) {

		this.getPublic(this.ENDPOINT + "/orders/" + orderId + "/status", callback, callbackError, true)

	}

	getPaymentStatus(paymentId, callback, callbackError) {

		this.getPublic(this.ENDPOINT + "/payments/" + paymentId + "/status", callback, callbackError, true)

	}

	getOrderFeedback(orderId, callback) {

		this.getPublic(this.ENDPOINT + "/orders/" + orderId + "/feedback", callback)

	}

	updateFeedback(orderId, qualityRating, locationRating, serviceRating, pricingRating, feedbackDetails, feedbackId, callback) {

		let data = {
			feedback_id: feedbackId,
			quality_rating: qualityRating,
			location_rating: locationRating,
			service_rating: serviceRating,
			pricing_rating: pricingRating,
			details: feedbackDetails,
		}
		this.postPublic(this.ENDPOINT + "/orders/" + orderId + "/feedback", data, callback)

	}

	redeemItems(orderToken, businessId, items, callback, callbackError = null) {

		let token = this.generateToken([orderToken, businessId, items]);
		let data = {
			token: token,
			items: items,
			business_id: businessId
		};
		this.postPublic(this.ENDPOINT + "/orders/" + orderToken +"/redeem", data, callback, callbackError, true);

	}

	redeemVariation(orderToken, businessId, productId, itemId, callback, callbackError = null) {

		let token = this.generateToken([orderToken, businessId, productId]);
		let data = {
			token: token,
			order_item_id: itemId,
			business_id: businessId
		};
		this.postPublic(this.ENDPOINT + "/orders/" + orderToken +"/"+productId+"/redeem", data, callback, callbackError, true);

	}

	businessRedeemItems(businessId, orderId, items, callback) {

		let data = {
			items: items,
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/"+orderId+"/redeem/items", data, callback);

	}

	unsubscribeEmailUpdate(email, callback) {

		let token = this.generateToken([email]);
		let data = {
			email: email,
			token: token
		}
		this.postPublic(this.ENDPOINT + "/newsletter/unsubscribe", data, callback);

	}

	getSocials(callback) {

		this.getPublic(this.ENDPOINT + "/socials", callback)

	}

	getThemes(callback) {

		this.getPublic(this.ENDPOINT + "/menu_themes", callback)

	}

	getAllergens(callback) {

		this.getPublic(this.ENDPOINT + "/allergens", callback)

	}

	getCountries(callback) {

		this.getPublic(this.ENDPOINT + "/countries", callback)

	}

	getLanguages(callback) {

		this.getPublic(this.ENDPOINT + "/languages", callback)

	}

	getArticles(callback) {

		this.getPublic(this.ENDPOINT + "/articles", callback)

	}

	getArticle(id, callback) {

		this.getPublic(this.ENDPOINT + "/articles/"+id, callback)

	}

	userAccount(callback) {

		this.get(this.ENDPOINT_USER + "/account", null, callback);

	}

	updateAccount(name, phone, callback) {

		let data = {
			name: name,
			phone: phone
		}
		this.post(this.ENDPOINT_USER + "/account/update", data, callback);

	}

	getStripeCustomerPortal(callback) {

		this.get(this.ENDPOINT_USER + "/stripe/portal", null, callback);

	}


	copyMenu(fromBusinessId, toBusinessId, menuId, callback) {

		let data = {
			from_business_id: fromBusinessId,
			to_business_id: toBusinessId,
			menu_id: menuId
		};
		this.post(this.ENDPOINT_USER + "/businesses/menus/copy", data, callback);
	}

	userCheckoutNotInPlace(businessId, captcha, items, notes, typePayment, address_id, address, addressDetails, lat, lng, dataAddress, test, takeawayTime, rateId, callback) {

		let token = this.generateToken([captcha, businessId]);
		let data = {
			token: token,
			captcha: captcha,
			items: items,
			notes: notes,
			type_payment: typePayment,
			address_id: address_id,
			address: address,
			address_details: addressDetails,
			lat: lat,
			lng: lng,
			data: dataAddress,
			test: test,
			takeaway_date: takeawayTime,
			rate_id: rateId,
			business_id: businessId
		};
		this.post(this.ENDPOINT_USER + "/checkout/not-in-place", data, callback);

	}

	getTwoFactorAuthSecret(callback) {

		this.get(this.ENDPOINT_USER + "/2fa", null, callback);

	}

	addTwoFactorAuth(secretCode, verificationCode, callback) {

		let data = {
			secret_code: secretCode,
			verification_code: verificationCode,
		};
		this.post(this.ENDPOINT_USER + "/2fa", data, callback);

	}

	addUserAddress(address, lat, lng, addressData, callback) {

		let data = {
			address: address,
			lat: lat,
			lng: lng,
			address_data: addressData
		};
		this.post(this.ENDPOINT_USER + "/addresses", data, callback);

	}

	getUserReferralsRewards(filters, callback) {
		let data = {
			filters: JSON.stringify(filters || {})
		}
		this.post(this.ENDPOINT_USER + "/referrals", data, callback);

	}

	assignCreditToBusiness(businessId, credit, callback) {
		let data = {
			credit
		}
		this.post(this.ENDPOINT_USER + "/businesses/"+businessId+"/credits", data, callback);
	}

	getReferralGenericUrl(callback) {
		this.post(this.ENDPOINT_USER + "/referrals/link", null, callback);
	}

	emailReferralInvite(email, lang, callback) {
		let data = {
			email,
			lang
		}
		this.post(this.ENDPOINT_USER + "/referrals/invite", data, callback);
	}

	superLogin(userId, businessId, callback) {

		this.get(this.ENDPOINT_USER + "/super/login/" + userId + "/" + businessId, null, callback);

	}

	getPaymentLink(businessId, link, callback) {

		this.get(this.ENDPOINT_USER + "/super/businesses/" + businessId + "/pay/" + link, null, callback);

	}

	getSuperInvoices(filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_USER + "/super/invoices", data, callback);

	}

	superReportTransaction(transactionId, callback) {

		this.get(this.ENDPOINT_USER + "/super/transactions/" + transactionId , null, callback);

	}

	emitInvoiceAgain(businessInvoiceId, callback) {

		let data = {
		}
		this.post(this.ENDPOINT_USER + "/super/invoices/" + businessInvoiceId + "/emit", data, callback);

	}

	superEmitInvoice(businessId, paid, object, items, callback) {

		let data = {
			paid: paid,
			object: object,
			items: items,
			business_id: businessId
		}
		this.post(this.ENDPOINT_USER + "/super/invoices/new", data, callback);

	}

	emitInvoiceManual(businessInvoiceId, callback) {

		let data = {
		}
		this.post(this.ENDPOINT_USER + "/super/invoices/" + businessInvoiceId + "/manual", data, callback);

	}

	getSuperLogs(filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_USER + "/super/logs", data, callback);

	}

	superUpdateBusinessPayoutInterval(businessId, interval, callback) {
		let data = {
			interval
		};
		this.post(this.ENDPOINT_USER + "/super/businesses/" + businessId + "/payout/interval", data, callback);
	}

	superUpdateBusinessPayoutDelay(businessId, delay, callback) {
		let data = {
			delay
		};
		this.post(this.ENDPOINT_USER + "/super/businesses/" + businessId + "/payout/delay", data, callback);
	}

	superUpdateBusinessFeeRules(businessId, type, rules, callback) {
		let data = {
			type: type,
			rules: rules,
		};
		this.post(this.ENDPOINT_USER + "/super/businesses/" + businessId + "/feerules", data, callback);
	}

	superReportCommissions(businessId, startDate, endDate, callback) {
		let data = {
			start_date: startDate,
			end_date: endDate
		};
		this.post(this.ENDPOINT_USER + "/super/businesses/" + businessId + "/commissions/report", data, callback);
	}

	superStatsBusinesses(callback) {

		this.get(this.ENDPOINT_USER + "/super/stats/businesses", null, callback);

	}

	superStatsOrders(startDate, endDate, businessIds, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
			business_ids: JSON.stringify(businessIds)
		};
		this.post(this.ENDPOINT_USER + "/super/stats/orders", data, callback);

	}

	superStatsPayments(filters, callback) {

		let data = {
			filters: filters
		};
		this.post(this.ENDPOINT_USER + "/super/stats/payments", data, callback);

	}

	superUpdateBusinessType(businessId, type, callback) {
		let data = {
			type: type
		};
		this.post(this.ENDPOINT_USER + "/super/businesses/" + businessId + "/type", data, callback);
	}

	superUpdateBusinessTransferPrefix(businessId, prefix, callback) {
		let data = {
			prefix: prefix
		};
		this.post(this.ENDPOINT_USER + "/super/businesses/" + businessId + "/prefix", data, callback);
	}

	superUpdateBusinessCredits(businessId, credits, repaymentRate, callback) {
		let data = {
			credits: credits,
			repayment_rate: repaymentRate
		};
		this.post(this.ENDPOINT_USER + "/super/businesses/" + businessId + "/credits", data, callback);
	}

	saveMagazineArticle(articleId, articleData, callback) {

		this.post(this.ENDPOINT_USER + "/super/articles/" + articleId, articleData, callback);

	}

	deleteMagazineArticle(articleId, callback) {

		this.post(this.ENDPOINT_USER + "/super/articles/" + articleId + "/delete", {}, callback);

	}

	getBusiness(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/", null, callback);

	}

	getBusinessUsers(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/users", null, callback);

	}

	updateBusinessUsers(businessId, permissions, callback) {

		let data = {
			users: JSON.stringify(permissions)
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/users/update", data, callback);

	}

	getWarehouseItems(businessId, callback) {

		let data = {
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/warehouse/items", data, callback);

	}

	deleteWarehouseItem(businessId, menuItemWarehouseId, callback) {

		let data = {
			menu_item_warehouse_id: menuItemWarehouseId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/warehouse/items/delete", data, callback);

	}

	updateWarehouseItemSettings(businessId, menuItemWarehouseId, affectsAvailability, callback) {

		let data = {
			menu_item_warehouse_id: menuItemWarehouseId,
			affects_availability: affectsAvailability
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/warehouse/items/settings", data, callback);

	}

	getFilteredLogsWarehouseProduct(businessId, menuItemWarehouseId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/warehouse/items/"+menuItemWarehouseId+"/logs", data, callback);

	}

	getBusinessLogs(businessId, startDate, type, lastKey, callback) {

		let data = {
			start_date: startDate,
			type: type,
			last_key: lastKey
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/logs", data, callback);

	}

	addWarehouseItem(businessId, menuItemId, sku, callback) {

		let data = {
			item_id: menuItemId,
			sku: sku
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/warehouse/items/new", data, callback);

	}

	updateWarehouseItems(businessId, items, callback) {

		let data = {
			items: JSON.stringify(items)
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/warehouse/items/update", data, callback);

	}

	deleteBusiness(businessId, password, callback) {

		let data = {
			password: password
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/delete", data, callback);

	}

	addBusinessUser(businessId, email, permissions, expires, callback) {

		let data = {
			email: email,
			permissions: JSON.stringify(permissions),
			expires: expires
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/users", data, callback);

	}

	getBusinessActions(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/actions", {}, callback);

	}
	addBusinessAction(businessId, actionId, callback) {

		let data = {
			action_id: actionId
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/actions", data, callback);

	}

	newBusiness(name, type, address, address_data, lat, lng, copyFromBusinessid, callback) {

		let data = {
			business_name: name,
			address: address,
			address_data: address_data,
			lat: lat,
			lng: lng,
			type: type,
			copy_from_business_id: copyFromBusinessid
		};
		this.post(this.ENDPOINT_USER + "/business", data, callback);

	}

	updateBusiness(businessId, data, callback) {

		Object.entries(data).forEach(([key, value]) => {
			if(value === true) {
				data[key] = 1;
			} else if(value === false) {
				data[key] = 0;
			}
		});
		this.post(this.ENDPOINT_BUSINESS + businessId + "/update", data, callback);

	}

	updateBusinessFunnelPreferences(businessId, dismissedFunnel, callback) {
		const data = {
			dismissed_funnel: dismissedFunnel
		}

		this.post(this.ENDPOINT_BUSINESS + businessId + "/funnel/update", data, callback);

	}

	getOrders(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/orders", null, callback);

	}

	searchOrders(businessId, limit, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/orders/limit/" + limit, null, callback);

	}

	getOrderWithId(businessId, order_id, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/orders/" + order_id, null, callback);

	}

	getUnfiscalizedOrdersLastDay(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/orders/today/unfiscalized", null, callback);

	}

	getEpsonReceiptData(businessId, epsonId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/orders/fiscal/epson/"+epsonId, null, callback);

	}

	logError(businessId, rawRequest, rawResponse, code, lastCommand, printerStatus, callback) {

		let data = {
			raw_request: rawRequest,
			raw_response: rawResponse,
			code: code,
			last_command: lastCommand,
			status: printerStatus
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/fiscal/log", data, callback);

	}

	checkOrdersFiscal(businessId, ordersId, callback) {

		let data = {
			order_ids: JSON.stringify(ordersId)
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/fiscal/check", data, callback);

	}

	generateFiscalReceiptApiscontrino(businessId, ordersId, callback) {

		let data = {
			order_ids: JSON.stringify(ordersId)
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/fiscal/apiscontrino", data, callback);

	}

	printFiscalReceiptApiscontrino(businessId, receiptId, callback) {

		let data = {
			receipt_id: receiptId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/fiscal/apiscontrino/print", data, callback);

	}

	sendFiscalReceiptEmail(businessId, receiptId, email, callback) {

		let data = {
			receipt_id: receiptId,
			email: email
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/fiscal/apiscontrino/email", data, callback);

	}

	acceptOrder(businessId, orderId, orderAccepted, callback) {

		let data = {
			order_accepted: orderAccepted
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/" + orderId + "/accept", data, callback);

	}

	addBusinessDevice(businessId, device, token, soundIndex, callback) {

		let data = {device: device, token: token, sound: soundIndex};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/devices", data, callback);

	}

	setOrdersFiscal(businessId, ordersId, receiptData, callback) {

		let data = {
			order_ids: JSON.stringify(ordersId),
			receipt_data: receiptData
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/fiscal", data, callback);

	}
	setOrdersFiscalSent(businessId, ordersId, sendingBusinessAction, callback) {

		let data = {
			order_ids: JSON.stringify(ordersId),
			business_action: sendingBusinessAction
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/fiscal/sent", data, callback);

	}

	changeOrders(businessId, orderIds, action, businessAction, callback) {

		let data = {
			order_ids: orderIds,
			action: action,
			business_action: businessAction
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/update", data, callback);

	}

	changeOrdersPayment(businessId, orderIds, paymentType, invoiceData, businessAction, callback) {

		let data = {
			order_ids: orderIds,
			payment_type: paymentType,
			invoiceData: invoiceData,
			business_action: businessAction
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/payment", data, callback);

	}

	addOrderMessage(businessId, orders_id, textNotice, callback) {

		let data = {
			orders_id: orders_id,
			text_notice: textNotice
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/messages", data, callback);
	}

	sendSms(businessId, orders_id, callback) {

		let data = {
			orders_id: orders_id
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/send-sms", data, callback);

	}

	getOrdersNew(businessId, roomsFilter, counterFilter, deliveryFilter, takeawayFilter, onlyMyOrders, page_size, page_offset, callback) {

		let data = {
			page_size: page_size,
			page_offset: page_offset,
			rooms_filter: roomsFilter,
			counter_filter: counterFilter,
			delivery_filter: deliveryFilter,
			takeaway_filter: takeawayFilter,
			only_my_orders: onlyMyOrders
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/list", data, callback);

	}

	getOrdersCompletedNew(businessId, roomsFilter, counterFilter, deliveryFilter, takeawayFilter, onlyMyOrders, page_size, page_offset, code, callback) {

		let data = {
			page_size: page_size,
			page_offset: page_offset,
			rooms_filter: roomsFilter,
			counter_filter: counterFilter,
			delivery_filter: deliveryFilter,
			takeaway_filter: takeawayFilter,
			only_my_orders: onlyMyOrders,
			code: code
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/list/completed", data, callback);

	}

	searchOrdersNew(businessId, search, completed, roomsFilter, counterFilter, deliveryFilter, takeawayFilter, onlyMyOrders, page_size, page_offset, code, callback) {

		let data = {
			search: search,
			completed: completed,
			page_size: page_size,
			page_offset: page_offset,
			rooms_filter: roomsFilter,
			counter_filter: counterFilter,
			delivery_filter: deliveryFilter,
			takeaway_filter: takeawayFilter,
			only_my_orders: onlyMyOrders,
			code: code
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/list/search", data, callback);
	}

	viewedRoomTableCall(businessId, roomTableCallId, callback) {

		let data = {};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms/tables/calls/" + roomTableCallId + "/view", data, callback);
		
	}

	getOrdersUpdates(businessId, orderIds, lastTime, requestFiscal, requestTablesCalls, onlyMyOrders, callback, callbackError) {

		let data = {
			tracking_orders_ids: orderIds,
			time: lastTime,
			request_fiscal: requestFiscal,
			request_tables_calls: requestTablesCalls,
			only_my_orders: onlyMyOrders
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/orders/list/update", data, callback, callbackError, true);

	}

	getRedeemedQuantities(orderToken, callback, callbackError) {

		this.getPublic(this.ENDPOINT + "/orders/" + orderToken +"/redeem", callback, callbackError, true);

	}

	createPaymentLink(productsQuantities, callback) {
		let data = {products: JSON.stringify(productsQuantities)};
		this.post(this.ENDPOINT + "/checkout/stripe/link", data, callback);
	}

	checkoutProducts(products, customerId, businessId, successUrl, failUrl, callback) {
		let data = {products: JSON.stringify(products), customer_id: customerId, business_id: businessId, success_url: successUrl, fail_url: failUrl};
		this.post(this.ENDPOINT + "/checkout/stripe/products", data, callback);
	}

	checkCoupons(businessId, coupons, cart, total, roomId, orderMode, callback) {
		let data = {
			business_id: businessId,
			coupons: JSON.stringify(coupons),
			cart: cart,
			total: total,
			room_id: roomId,
			order_mode: orderMode,
			token: this.generateToken([businessId, cart, orderMode])
		};
		this.post(this.ENDPOINT + "/checkout/coupons", data, callback);
	}

	statsOrders(businessId, period, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/data/orders/" + period, null, callback);

	}

	ordersDay(businessId, day, callback) {

		let data = {
			day: day
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/orders/day", data, callback);

	}

	analyticsViews(businessId, startDate, endDate, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/views", data, callback);

	}

	analyticsHeatMap(businessId, startDate, endDate, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/map", data, callback);

	}

	analyticsOrders(businessId, startDate, endDate, code, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
			code: code
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/orders", data, callback);

	}

	exportWeconstudio(businessId, startDate, endDate, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/weconstudio/export", data, callback);

	}

	getWeather(businessId, startDate, endDate, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/weather", data, callback);

	}

	analyticsCustomers(businessId, startDate, endDate, code, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
			code: code
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/customers", data, callback);

	}

	analyticsProducts(businessId, startDate, endDate, code, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
			code: code
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/products", data, callback);

	}

	getProductsUsersScan(businessId, startDate, endDate, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/products/scan", data, callback);

	}

	analyticsRooms(businessId, startDate, endDate, code, callback) {

		let data = {
			start_date: startDate,
			end_date: endDate,
			code: code
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/data/rooms", data, callback);

	}

	getMenus(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/menus", null, callback);

	}

	getPreSignedUrl(businessId, filename, filesize, callback) {

		let data = {
			filename: filename,
			filesize: filesize
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menus/image/upload", data, callback);

	}

	newMenu(businessId, name, callback) {

		let data = {
			name: name || null
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menus", data, callback);

	}

	deleteMenu(businessId, menuId, callback) {

		let data = {
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menus/" + menuId + "/delete", data, callback);

	}

	updateMenu(businessId, menuId, name, color, picture, slug, available, roomsExclude, callback) {

		let data = {
			name: name,
			color: color,
			picture: picture,
			slug: slug,
			available: available,
			rooms_exclude: roomsExclude
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menus/" + menuId + "/update", data, callback);

	}

	updateMenusOrder(businessId, menus, callback) {

		let data = {
			menus: JSON.stringify(menus)
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menus/order", data, callback);

	}

	decodeMenuFile(businessId, file, callback) {

		let data = {
			file: file
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menus/decode", data, callback);

	}

	checkMenuRun(businessId, runId, threadId, messageId, callback) {

		let data = {
			run_id: runId,
			thread_id: threadId,
			message_id: messageId
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menus/run", data, callback);

	}

	getMenuCategories(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/menu/categories", null, callback);

	}

	getCategoryTimes(businessId, category_id, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/menu/categories/" + category_id + "/times", null, callback);

	}

	updateMenuCategories(businessId, categories, menuId, callback) {

		let data = {
			categories: categories,
			menu_id: menuId
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/categories", data, callback);

	}

	updateMenuCategoryTaxonomy(businessId, categoryId, taxonomy, callback) {

		let data = {
			taxonomy: taxonomy,
			category_id: categoryId
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/category/taxonomy", data, callback);

	}

	addCategoryTime(businessId, dayWeek, categoryId, hourFrom, hourTo, minuteFrom, minuteTo, numItems, callback) {

		let data = {
			day_week: dayWeek,
			hour_start: hourFrom,
			hour_end: hourTo,
			minute_start: minuteFrom,
			minute_end: minuteTo,
			num_items: numItems
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/categories/" + categoryId + "/times", data, callback);

	}

	copyCategoryTime(businessId, dataTimes, categoryId, callback) {

		let data = {
			data_times: dataTimes,
			category_id: categoryId
		}

		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/categories/" + categoryId + "/times/copy", data, callback);

	}

	deleteCategoryTime(businessId, categoryId, category_time_id, callback) {

		let data = {
			category_time_id: category_time_id
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/categories/" + categoryId + "/times/delete", data, callback);

	}

	getMenuItems(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/menu/items", null, callback);

	}

	getCompositeProduct(businessId, item_id, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + item_id, null, callback);

	}

	getMenuItemsListsInfo(businessId, itemIds, callback) {

		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/lists/info/multi", {items_ids: JSON.stringify(itemIds)}, callback);

	}

	getItemTimes(businessId, item_id, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + item_id + "/times", null, callback);
	}

	getListInformation(businessId, itemId, list_id, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + itemId + "/lists/" + list_id, null, callback);

	}

	menuItem(businessId, item, callback) {

		let data = {
			item: item
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items", data, callback);

	}

	updateMenuItems(businessId, items, menuId, callback, returnNewItems = false) {

		let data = {
			items: items,
			menu_id: menuId
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/update", data, callback);

	}

	updateMenuItemsAvailabilities(businessId, items, callback) {

		let data = {
			items: items
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/update/availabilities", data, callback);

	}

	menuItemsDeleteImage(businessId, typeItem, itemId, callback) {

		let data = {
			type_item: typeItem
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + itemId + "/images/delete", data, callback);

	}

	saveListInformation(businessId, item_id, list_id, name_list, min_list, max_list, multi_list, order_list, deleted_list, products_list, callback) {

		let data = {
			list_id: list_id,
			name_list: name_list,
			min_list: min_list,
			max_list: max_list,
			multi_list: multi_list,
			order_list: order_list,
			deleted_list: deleted_list,
			products_list: products_list
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + item_id + "/lists", data, callback);

	}

	saveListsInformation(businessId, items, callback) {

		let data = {
			items: JSON.stringify(items),
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/lists", data, callback);

	}

	updateMenuItemLists(businessId, menuItemId, lists, callback) {

		let data = {
			lists: JSON.stringify(lists),
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/"+menuItemId+"/lists/update", data, callback);

	}

	addItemTime(businessId, dayWeek, itemId, hourFrom, hourTo, minuteFrom, minuteTo, numItems, callback) {

		let data = {
			day_week: dayWeek,
			hour_start: hourFrom,
			hour_end: hourTo,
			minute_start: minuteFrom,
			minute_end: minuteTo,
			num_items: numItems
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + itemId + "/times", data, callback);

	}

	copyItemTime(businessId, dataTimes, itemId, callback) {

		let data = {
			data_times: dataTimes
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + itemId + "/times/copy", data, callback);

	}

	deleteItemTime(businessId, itemId, item_time_id, callback) {

		let data = {
			item_time_id: item_time_id
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + itemId + "/times/delete", data, callback);

	}

	updateItemTimes(businessId, itemId, times, callback) {

		let data = {
			data_times: times
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/" + itemId + "/times/update", data, callback);

	}

	copyItemsTimes(businessId, items, callback) {

		let data = {
			items: JSON.stringify(items)
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/items/times/copy", data, callback);

	}

	updateCategoryTimes(businessId, categoryId, times, callback) {

		let data = {
			data_times: times
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menu/categories/" + categoryId + "/times/update", data, callback);

	}

	updateDeliveryTimes(businessId, times, callback) {

		let data = {
			data_times: times
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/delivery/times/update", data, callback);

	}

	updateTakeawayTimes(businessId, times, callback) {

		let data = {
			data_times: times
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/takeaway/times/update", data, callback);

	}

	getRooms(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/rooms", null, callback);

	}

	getRoomStatus(businessId, roomId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/rooms/" + roomId, null, callback);

	}

	addRoom(businessId, name, acceptOrders, enabledCalls, elements, callback) {

		let data = {
			name: name,
			accept_orders: acceptOrders,
			enabled_calls: enabledCalls,
			elements: elements
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms", data, callback);

	}

	addRoomElements(businessId, roomId, elements, callback) {

		let data = {
			elements: elements
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms/" + roomId + "/elements/add", data, callback);

	}

	updateRoom(businessId, roomId, name, acceptOrders, preventOrderCompletion, enabledSms, enabledCalls, cover, enablePayBill, forcePayBill, notificationSound, callback) {

		let data = {
			name: name,
			accept_orders: acceptOrders,
			prevent_order_completion: preventOrderCompletion,
			enabled_sms: enabledSms,
			enabled_calls: enabledCalls,
			enable_pay_bill: enablePayBill,
			cover_charge: cover?.cover_charge,
			cover_charge_type: cover?.cover_charge_type,
			cover_charge_name: cover?.cover_charge_name,
			cover_charge_everytime: cover?.cover_charge_everytime,
			force_pay_bill: forcePayBill,
			notification_sound: notificationSound,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms/"+roomId+"/update", data, callback);

	}

	deleteRoom(businessId, roomId, callback) {

		let data = {}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms/"+roomId+"/delete", data, callback);

	}

	getCoupons(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/coupons", null, callback);

	}

	deleteCoupon(businessId, couponId, callback) {

		this.post(this.ENDPOINT_BUSINESS + businessId + "/coupons/"+couponId+"/delete", null, callback);

	}

	addCoupon(businessId, name, code, fixedValue, percentValue, maxRedeems, minOrderValue, canStack, oncePerUser, firstTimeOnly, qrMode, delivery, takeaway, linkedProducts, linkedCategories, linkedRooms, expireDate, callback) {

		let data = {
			name: name,
			code: code,
			fixed_value: fixedValue,
			percentage_value: percentValue,
			max_redeems: maxRedeems,
			minimum_order_value: minOrderValue,
			can_stack: canStack,
			once_per_user: oncePerUser,
			first_time_only: firstTimeOnly,
			qr_mode: qrMode,
			delivery: delivery,
			takeaway: takeaway,
			linked_products: linkedProducts,
			linked_categories: linkedCategories,
			linked_rooms: linkedRooms,
			date_expire: expireDate
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/coupons", data, callback);

	}

	updateRoomType(businessId, roomId, type, callback) {

		let data = {
			type: type
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms/" + roomId + "/type", data, callback);

	}

	saveRoomTables(businessId, roomId, elements, callback) {

		let data = {
			elements: elements
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms/" + roomId + "/elements", data, callback);

	}

	deleteRoomTable(businessId, roomTableId, callback) {

		let data = {
			room_table_id: roomTableId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms/tables/remove", data, callback);

	}

	requestQrPrint(businessId, type, quantity, callback) {

		let data = {
			type: type,
			quantity: quantity
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/rooms/tables/request", data, callback);

	}

	connectStripe(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/connect", null, callback);

	}

	getFilteredStripeBalance(businessId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/balance/info", data, callback);

	}

	getReconcileTransactionsTransfer(businessId, transferId, callback) {

		let data = {
			transfer_id: transferId,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/transactions", data, callback);
	}

	getFilteredInvoices(businessId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/invoices", data, callback);

	}

	getFilteredBusinessToCustomerInvoices(businessId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/invoices/b2c", data, callback);

	}

	getBusinessFeedbacks(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/feedbacks", null, callback);

	}

	getStripeExpressDashboardUrl(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/express/dashboard", null, callback);

	}

	getStripeIdentityVerificationSession(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/identity/verification", null, callback);

	}

	updateBusinessPayoutDelay(businessId, newDelay, callback) {

		let data = {
			payout_delay: newDelay
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/payout/delay", data, callback);

	}

	getFilteredStripeTransactions(businessId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/transactions/info", data, callback);

	}

	getFilteredBalanceChanges(businessId, filters, callback) {

		let data = {
			filters: filters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/balance/history", data, callback);

	}

	refundStripeTransaction(businessId, transactionId, amount, reason, callback) {

		let data = {
			amount: amount || null,
			reason: reason || null,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/transaction/"+transactionId+"/refund", data, callback);

	}

	sendTransactionReceiptEmail(businessId, email, transactionId, callback) {

		let data = {
			email: email
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/stripe/transaction/"+transactionId+"/receipt", data, callback);

	}

	satispayCreate(businessId, active, satispay_token, callback) {

		let data = {
			satispay_token: satispay_token,
			active: active
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/satispay/create", data, callback);

	}

	voucherlyCreate(businessId, active, apiKey, callback) {

		let data = {
			api_key: apiKey,
			active
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/voucherly/create", data, callback);

	}

	btcpayCreate(businessId, password, callback) {

		let data = {
			password: password
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/payments/btc/create", data, callback);

	}

	btcpayInvoiceTest(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/payments/btc/invoice/test", null, callback);

	}

	stripeSubscriptionMetered(businessId, dataAction, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/buy/metered/" + dataAction, null, callback);

	}

	stripeUploadMenuService(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/buy/menu-service", null, callback);

	}

	stripeSubscriptionBase(businessId, billingCycle, withPlugins, callback) {

		let data = {
			plugins: JSON.stringify(withPlugins),
			billing_cycle: billingCycle
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/buy/base", data, callback);

	}

	stripeBuyPlugins(businessId, orderPay, delivery, takeaway, pos, counter, payBill, callback) {

		let data = {
			plan_plugin_order_pay: orderPay,
			plan_plugin_delivery: delivery,
			plan_plugin_takeaway: takeaway,
			plan_plugin_pos: pos,
			plan_plugin_counter: counter,
			plan_plugin_pay_bill: payBill
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/buy/plugins", data, callback);

	}

	stripeToggleExternalPlugins(businessId, pluginId, enable, callback) {

		let data = {
			external_plugin_id: pluginId,
			enable: !!enable
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/buy/plugins/external", data, callback);

	}

	stripeSubscriptionCycleUpdate(businessId, planCycle, callback) {

		let data = {
			plan_cycle: planCycle
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/buy/cycle", data, callback);

	}

	createLink(businessId, name, callback) {

		let data = {
			name: name
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/link", data, callback);

	}

	updateBusinessManualCode(businessId, code, callback) {

		let data = {
			code: code
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/manual/code", data, callback);

	}

	updatePaymentMethods(businessId, accept_cash, accept_cards, accept_bitcoin, accept_satispay, accept_klarna, accept_voucherly, callback) {

		let data = {
			accept_cash,
			accept_cards,
			accept_bitcoin,
			accept_satispay,
			accept_klarna,
			accept_voucherly
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/payment-methods", data, callback);

	}

	updateHidePrices(businessId, hide_prices, callback) {

		let data = {
			hide_prices: hide_prices
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/hide-prices", data, callback);

	}

	updateIntegration(businessId, integration, callback) {

		let data = {
			integration: integration
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/integration", data, callback);

	}

	syncWeconstudio(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/integrations/weconstudio/sync", null, callback);

	}

	updateBusinessDelivery(businessId, delivery_active, callback) {

		let data = {
			delivery_active: delivery_active
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/delivery", data, callback);

	}

	addNewAddress(businessId, address, lat, lng, addressData, insertType, callback) {

		let data = {
			address: address,
			lat: lat,
			lng: lng,
			address_data: addressData,
			insert_type: insertType
		};
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/address", data, callback);

	}

	saveSocialUrl(businessId, social, callback) {

		let data = {social: social}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/socials", data, callback);

	}

	getDeliveryTimes(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/delivery/times", null, callback);

	}

	updateDeliveryCash(businessId, delivery_cash, callback) {

		let data = {
			delivery_cash: delivery_cash,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/delivery/cash", data, callback);

	}

	addDeliveryTime(businessId, dayWeek, hourFrom, hourTo, minuteFrom, minuteTo, numOrders, callback) {

		let data = {
			day_week: dayWeek,
			hour_start: hourFrom,
			hour_end: hourTo,
			minute_start: minuteFrom,
			minute_end: minuteTo,
			num_orders: numOrders
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/delivery/times", data, callback);

	}

	getDeliveryCouriers(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/delivery/couriers", null, callback);

	}

	updateDeliveryCouriers(businessId, couriers, callback) {

		let data = {
			couriers: JSON.stringify(couriers)
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/delivery/couriers", data, callback);

	}

	copyDeliveryTime(businessId, dataTimes, callback) {

		let data = {
			data_times: dataTimes
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/delivery/times/copy", data, callback);

	}

	deleteDeliveryTime(businessId, delivery_time_id, callback) {

		let data = {
			delivery_time_id: delivery_time_id
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/delivery/times/delete", data, callback);

	}

	getTakeawayTimes(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/takeaway/times", null, callback);

	}

	updateTakeawayCash(businessId, takeaway_cash, callback) {

		let data = {
			takeaway_cash: takeaway_cash,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/takeaway/cash", data, callback);
	}

	addTakeawayTime(businessId, dayWeek, hourFrom, hourTo, minuteFrom, minuteTo, numOrders, callback) {

		let data = {
			day_week: dayWeek,
			hour_start: hourFrom,
			hour_end: hourTo,
			minute_start: minuteFrom,
			minute_end: minuteTo,
			num_orders: numOrders
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/takeaway/times", data, callback);

	}

	copyTakeawayTime(businessId, dataTimes, callback) {

		let data = {
			data_times: dataTimes
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/takeaway/times/copy", data, callback);

	}

	deleteTakeawayTime(businessId, takeaway_time_id, callback) {

		let data = {
			takeaway_time_id: takeaway_time_id
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/takeaway/times/delete", data, callback);

	}

	getPrinters(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/printers", null, callback);

	}

	getTerminals(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/terminals", null, callback);

	}

	updateTerminals(businessId, newTerminals, callback) {

		let data = { terminals: newTerminals }
		this.post(this.ENDPOINT_BUSINESS + businessId + "/terminals", data, callback);

	}

	printTest(businessId, printer_id, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/printers/" + printer_id + "/test", null, callback);

	}

	updateTerminalConfiguration(businessId, terminalId, configuration, callback) {

		this.post(this.ENDPOINT_BUSINESS + businessId + `/terminals/${terminalId}/configuration`, configuration, callback);

	}

	printerChangeCategory(businessId, category_id, categories, callback) {

		let data = {
			category_id: category_id,
			categories: categories
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/categories", data, callback);

	}

	printerUpdateExcludedCategories(businessId, id, categories, categoriesSmall, users, callback) {

		let data = {
			categories: categories,
			categoriesSmall: categoriesSmall,
			users: users
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printer/"+id+"/categories", data, callback);

	}

	printerUpdate(businessId, printer_id, name, active, print_order_total, name_break_line, product_double_height, white_space_header, left_side_quantities, header_double_size, print_redeem_qr, print_order_qr, print_exploded_items, edit_order_summary, can_reprint_orders, can_print_prebill, play_audio, fiscal_print, mode_counter_business, mode_qr_business, mode_delivery_business, mode_takeaway_business, mode_qr_customer, mode_delivery_customer, mode_takeaway_customer, callback) {

		let data = {
			id: printer_id,
			name: name,
			active: active,
			print_order_total: print_order_total,
			name_break_line: name_break_line,
			can_reprint_orders: can_reprint_orders,
			can_print_prebill: can_print_prebill,
			product_double_height: product_double_height,
			white_space_header: white_space_header,
			left_side_quantities: left_side_quantities,
			header_double_size: header_double_size,
			print_redeem_qr: print_redeem_qr,
			print_order_qr: print_order_qr,
			print_exploded_items: print_exploded_items,
			edit_order_summary: edit_order_summary,
			play_audio: play_audio,
			fiscal_print: fiscal_print,
			mode_counter_business: mode_counter_business,
			mode_qr_business: mode_qr_business,
			mode_delivery_business: mode_delivery_business,
			mode_takeaway_business: mode_takeaway_business,
			mode_qr_customer: mode_qr_customer,
			mode_delivery_customer: mode_delivery_customer,
			mode_takeaway_customer: mode_takeaway_customer,
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/update", data, callback);

	}

	deletePrinter(businessId, printerId, callback) {

		let data = {
			printer_id: printerId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/delete", data, callback);

	}

	clonePrinter(businessId, printerId, callback) {

		let data = {
			printer_id: printerId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/clone", data, callback);

	}

	registerNewPrinter(businessId, sn, callback) {

		let data = {
			sn: sn
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/new", data, callback);

	}

	printAggregateReceipt(businessId, ordersId, callback) {

		let data = {
			orders_id: ordersId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/print/aggregate", data, callback);

	}

	printReport(businessId, rows, date, appliedFilters, callback) {

		let data = {
			rows: rows,
			date: date,
			applied_filters: appliedFilters
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/print/report", data, callback);

	}

	printPrebill(businessId, ordersId, allItems, callback) {

		let data = {
			orders_id: ordersId,
			all_items: allItems
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/print/prebill", data, callback);

	}

	payTerminal(businessId, terminal_id, amount, noStats, orderIds, chosenPaymentMode, itemsRedeemed, invoiceData, callback) {

		let data = {
			amount: amount,
			no_stats: noStats,
			chosen_payment_mode: chosenPaymentMode,
			order_ids: JSON.stringify(orderIds),
			items_redeemed: JSON.stringify(itemsRedeemed),
			invoice_data: JSON.stringify(invoiceData)
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/terminals/"+terminal_id+"/pay", data, callback);

	}

	payTerminalLocal(businessId, amount, noStats, notes, orderIds, type, chosenPaymentMode, items_redeemed, invoiceData, callback) {

		let data = {
			amount: amount,
			no_stats: noStats,
			notes: notes,
			order_ids: JSON.stringify(orderIds),
			items_redeemed: JSON.stringify(items_redeemed),
			invoice_data: JSON.stringify(invoiceData),
			type: type,
			chosen_payment_mode: chosenPaymentMode
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/terminals/pay/local", data, callback);

	}

	cancelTerminalPayment(businessId, terminal_id, callback) {

		let data = {}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/terminals/"+terminal_id+"/cancel", data, callback);

	}

	deleteTerminal(businessId, terminal_id, callback) {

		let data = {}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/terminals/"+terminal_id+"/delete", data, callback);

	}

	addQromopayCredentials(businessId, name, url, callback) {

		let data = {
			name: name,
			url: url
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/qromopay", data, callback);

	}

	addNewWebhook(businessId, name, url, callback) {

		let data = {
			name: name,
			url: url
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/webhooks", data, callback);

	}

	deleteQromopayCredential(businessId, webhookId, callback) {

		let data = {}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/qromopay/"+webhookId+"/delete", data, callback);

	}

	deleteWebhook(businessId, webhookId, callback) {

		let data = {}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/webhooks/"+webhookId+"/delete", data, callback);

	}

	getPosOrder(businessId, terminal_id, order_id, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/terminals/"+terminal_id+"/orders/"+order_id, null, callback);

	}

	getBusinessLocation(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/terminals/location/default", null, callback);

	}

	printFiscalReceipt(businessId, orderId, callback) {

		let data = {
			order_id: orderId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/printers/print/fiscal", data, callback);

	}

	getLightspeedState(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/integrations/lightspeed/state", null, callback);

	}

	connectAde(businessId, adeLogin, adePassword, adePin, delegated, callback) {

		let data = {
			ade_login: adeLogin,
			ade_password: adePassword,
			ade_pin: adePin,
			delegated: delegated
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/integrations/apiscontrino/connect", data, callback);

	}

	disconnectAde(businessId, callback) {

		this.post(this.ENDPOINT_BUSINESS + businessId + "/integrations/apiscontrino/disconnect", {}, callback);

	}

	connectApifattura(businessId, prefixNumeration, sendSdi, callback) {

		let data = {
			prefix_numeration: prefixNumeration,
			send_sdi: sendSdi
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/integrations/apifattura/connect", data, callback);

	}

	disconnectApifattura(businessId, callback) {

		this.post(this.ENDPOINT_BUSINESS + businessId + "/integrations/apifattura/disconnect", {}, callback);

	}

	getLightspeedBusinesses(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/integrations/lightspeed/businesses", null, callback);

	}

	getLightspeedMenus(businessId, lightspeedBusinessLocationId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/integrations/lightspeed/businesses/"+lightspeedBusinessLocationId+"/menus", null, callback);

	}

	setLightspeedBusiness(businessId, lightspeedBusinessLocationId, menuId, callback) {

		let data = {
			lightspeed_location_id: lightspeedBusinessLocationId,
			lightspeed_menu_id: menuId
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/integrations/lightspeed/business", data, callback);

	}

	syncLightspeedMenu(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/integrations/lightspeed/sync/menu", null, callback);

	}

	syncLightspeedTables(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/integrations/lightspeed/sync/tables", null, callback);

	}

	testLightspeedOrder(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/integrations/lightspeed/test", null, callback);

	}

	getPublicCompanyInfoFromVat(vat, orderId, callback) {

		if(!vat || !vat?.length || vat?.length !== 11 || !orderId) {
			callback(null);
		} else {
			this.post(this.ENDPOINT + "/orders/"+orderId+"/vat", {vat}, callback);
		}

	}

	getBusinessCompanyInfoFromVat(vat, businessId, callback) {

		if(!vat || !vat?.length || vat?.length !== 11 || !businessId) {
			callback(null);
		} else {
			this.post(this.ENDPOINT_BUSINESS + businessId + "/customers/vat", {vat}, callback);
		}

	}

	loginUser(email, password, otp, callback) {

		let data = {
			grant_type: 'password',
			client_secret: this.CLIENT_SECRET,
			client_id: this.CLIENT_ID,
			username: email,
			password: password,
			otp: otp
		};
		this.postPublic(this.ENDPOINT + "/oauth/token", data, callback);

	}

	loginUserRefreshToken(tkn, callback, callbackError = null) {

		let data = {
			grant_type: 'refresh_token',
			client_secret: this.CLIENT_SECRET,
			client_id: this.CLIENT_ID,
			refresh_token: tkn
		};
		this.postPublic(this.ENDPOINT + "/oauth/token", data, callback, callbackError);

	}

	getBusinessShortcuts(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/settings/shortcuts", null, callback);

	}

	getBusinessApiscontrinoExcludeUsers(businessId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/settings/apiscontrino/users", null, callback);

	}

	updateBusinessShortcuts(businessId, data, callback) {

		let obj = {
			'shortcuts': data
		}

		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/shortcuts", obj, callback);

	}

	updateBusinessApiscontrinoExcludeUsers(businessId, data, callback) {

		let obj = {
			'excluded_users': data
		}

		this.post(this.ENDPOINT_BUSINESS + businessId + "/settings/apiscontrino/users", obj, callback);

	}

	getMenuRoomsExcluded(businessId, menuId, callback) {

		this.get(this.ENDPOINT_BUSINESS + businessId + "/menus/" + menuId + "/rooms/excluded", null, callback);

	}

	updateMenuRoomsExcluded(businessId, menuId, rooms, callback) {

		let data = {
			rooms: rooms
		}
		this.post(this.ENDPOINT_BUSINESS + businessId + "/menus/" + menuId + "/rooms/excluded", data, callback);

	}

}